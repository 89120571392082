<template>
  <span class="absolute top-0 h-full flex items-center right-3 text-gray-300 pointer-events-none">
    <locked-icon></locked-icon>
  </span>
</template>

<script>
import { LockedIcon } from "@/components/icons"
export default {
  components: {
    LockedIcon,
  },
}
</script>
