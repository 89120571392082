<template>
  <div class="flex flex-col space-y-1 mb-5">
    <InputLabel v-if="label" :id="id || name" :required="required">
      {{ label }}
    </InputLabel>
    <div class="border rounded-lg border-gray-200 flex items-center mb-2">
      <OnClickOutside class="relative w-full" @trigger="onClickOutside">
        <span
          class="text-gray-500 h-full absolute top-0 left-0 w-10 pl-2 flex items-center justify-center"
        >
          <svg class="stroke-current w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
        <input
          v-show="isSearching"
          :id="inputId"
          ref="queryInput"
          v-model="query"
          type="text"
          class="appearance-none border-none block w-full pr-3 pl-12 pb-2 pt-2 cursor-pointer border placeholder-gray-300 rounded text-gray-600 font-medium text-xs p-3 h-12 shadow-sm focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300 bg-white disabled:bg-gray-50 border-gray-300 hover:border-gray-400 disabled:cursor-not-allowed"
          :placeholder="placeholder"
          :disabled="disabled"
          :required="required"
          @focus="onFocusSearch"
          @blur="onBlurSearch"
        />
        <input type="hidden" :name="name" :value="selectedEmployee" />
        <div
          v-if="selectedEmployee && !isSearching"
          class="appearance-none rounded-lg border-none block w-full pr-3 pl-12 pb-2 pt-4 focus:outline-none appearance-none cursor-pointer w-full border border-gray-300 hover:border-gray-400 placeholder-gray-300 rounded text-gray-600 font-medium text-xs p-3 h-12 shadow-sm focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300 border hover:border-gray-400"
          :class="{
            'bg-gray-50 cursor-not-allowed': disabled,
            'bg-white hover:border-gray-400': !disabled,
          }"
          @click="onFocusSearch"
        >
          {{ selectedEmployeeName }}
          <disabled-icon v-if="disabled"></disabled-icon>
        </div>
        <slide-in dir="down">
          <ul
            v-show="dropVisible"
            class="max-h-64 overflow-y-auto absolute inset-x-0 mt-1 bg-white border border-gray-200 py-2 px-3 rounded text-xs z-50 shadow"
          >
            <li v-show="employees.length == 0">
              <p><em v-html="$t('orders.empty_search_result')"></em></p>
            </li>
            <li v-show="selectedEmployee" class="bg-gray-50">
              <p
                class="flex items-center justify-between p-3 font-semibold hover:bg-gray-150 cursor-pointer rounded transition"
              >
                {{ selectedEmployeeName }}
                <a href="javascript:" class="text-xs float-right p-1" @click="clearSelectedEmployee"
                  >Clear</a
                >
              </p>
            </li>
            <li v-for="employee in employees" :key="employee.id">
              <label
                v-if="employee.id != selectedEmployee"
                class="flex items-center justify-between p-3 font-semibold hover:bg-gray-150 cursor-pointer rounded transition"
                @click="selectEmployee(employee.id)"
              >
                <p class="text-gray-600 font-semibold leading-tight">
                  {{ employee.first_name }} {{ employee.last_name }}
                </p>
              </label>
            </li>
          </ul>
        </slide-in>
        <error-message v-if="error">{{ error }}</error-message>
        <help-message v-if="helpText">{{ helpText }}</help-message>
      </OnClickOutside>
    </div>
  </div>
</template>

<script>
import SlideIn from "@/components/transitions/SlideIn.vue"
import { OnClickOutside } from "@vueuse/components"
import _ from "lodash"
import InputLabel from "@/components/elements/inputs/_base/InputLabel.vue"
import ErrorMessage from "@/components/forms/_base/ErrorMessage.vue"
import HelpMessage from "@/components/forms/_base/HelpMessage.vue"
import DisabledIcon from "@/components/forms/_base/DisabledIcon.vue"
import httpClient from "@/helpers/http"

export default {
  components: {
    SlideIn,
    InputLabel,
    HelpMessage,
    OnClickOutside,
    ErrorMessage,
    DisabledIcon,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      query: "",
      selectedEmployee: this.value,
      selectedEmployeeName: "",
      dropVisible: false,
      employees: [],
      isLoading: false,
      isSearching: true,
    }
  },
  computed: {
    inputId() {
      return this.id || this.name || String(Math.random()).slice(2)
    },
  },
  watch: {
    query: function (value) {
      if (!value) return
      this.fetchEmployees()
    },
    inputValue(val) {
      this.$emit("input", val)
    },
    value(val) {
      this.inputValue = val
    },
  },
  created() {
    if (this.value) {
      this.fetchEmployeeById()
    }
  },
  methods: {
    onClickOutside(/*event*/) {
      this.dropVisible = false
      if (this.selectedEmployee) this.isSearching = false
    },
    onFocusSearch() {
      this.dropVisible = true
      this.isSearching = true

      let vs = this
      setTimeout(function () {
        vs.$refs.queryInput.focus()
      }, 300)
    },
    onBlurSearch() {
      this.dropVisible = false
      if (this.selectedEmployee) this.isSearching = false
    },
    selectEmployee: function (employeeId) {
      const employee = this.employees.find(employee => employee.id == employeeId)
      if (employee) {
        this.selectedEmployee = employeeId
        this.selectedEmployeeName = employee.first_name + " " + employee.last_name
        this.$emit("input", employeeId)
        this.isSearching = false
      }
      this.dropVisible = false
    },
    clearSelectedEmployee: function () {
      this.selectedEmployee = null
      this.selectedEmployeeName = null
      this.employees = []
      this.query = ""
      this.isSearching = true
    },
    fetchEmployees: _.debounce(function () {
      if (!this.query) return

      let q = this.query
      this.isLoading = true
      httpClient
        .get(this.zRoute("employees.search"), { params: { q } })
        .then(res => {
          this.employees = res.data
          this.isLoading = false
        })
        .catch(err => console.error(err))
    }, 300),
    fetchEmployeeById: _.debounce(function () {
      if (!this.selectedEmployee) return

      let q = this.selectedEmployee
      this.isLoading = true
      httpClient
        .get(this.zRoute("employees.search-by-id"), { params: { q } })
        .then(res => {
          this.employees = res.data
          this.isLoading = false
          const employee = this.employees.find(employee => employee.id == this.selectedEmployee)
          if (employee) {
            this.selectedEmployeeName = employee.first_name + " " + employee.last_name
            this.isSearching = false
          }
        })
        .catch(err => console.error(err))
    }, 300),
  },
}
</script>
